$fa-font-path: "../node_modules/@fortawesome/fontawesome-free/webfonts";

@import "node_modules/@fortawesome/fontawesome-free/scss/fontawesome.scss";
@import "node_modules/@fortawesome/fontawesome-free/scss/solid.scss";
@import "node_modules/@fortawesome/fontawesome-free/scss/brands.scss";

@import "styles/karree49";
@import "~bootstrap/scss/bootstrap";
@import "~bootstrap4-fullscreen-modal/src/bootstrap4-modal-fullscreen.scss";

@import "styles/cookiebanner";
@import "styles/content";
//@import "styles/slideshow/all";
@import "styles/nav/all";
//@import "styles/gallery/caption";
@import "styles/renting";
@import "styles/footer";



html {
  scroll-behavior: smooth;
}

.print-gallery {
  display: none;
}

@media print {
  html {
    zoom: 0.8;
  }

  .close {
    display: none !important;
  }

  .modal-fullscreen {
    -ms-overflow-style: none; // Internet Explorer 10+
    scrollbar-width: none; // Firefox
  }

  .modal-fullscreen::-webkit-scrollbar {
    display: none; // Safari and Chrome
  }

  .row.no-page-break {
    display: block !important;
  }

  .gallery-items {
    display: none;
  }

  .renting-logo {
    text-align: right;
    width: 50%;
  }

  .print-gallery {
    display: block;

    .gallery-image {
      margin-bottom: 1rem;
    }
  }

  @page {

    size: a4 portrait;
    margin: 10mm 10mm;
  }
}

body {
  font-size: 1.0rem;

  @include media-breakpoint-up(md) {
    font-size: 1.0rem;
  }

  @include media-breakpoint-up(xl) {
    padding-right: 0;
    font-size: 1.2rem;
  }
}

/* make room for the nav bar */
div.row.section[id] {
  padding-top: 105px;
  margin-top: -60px;
}

div.row.intro[id] {
  padding-top: 120px;
  margin-top: -68px;
}

.col--green, .bg-green {
  background-color: $fern;
}

.col--blue {
  background-color: $navy;

}

.col--karree49 {
  background-color: $tacao;
  h1 {
    font-weight: bold;
  }
}

.col--black {
  background-color: $gray-900;
}

.modal.modal-fullscreen {
  padding-right: 0px !important;

  &.modal-color--green .modal-content {
    background-color: $fern;
  }

  &.modal-color--blue .modal-content {
    background-color: $navy;
  }

  &.modal-color--black .modal-content {
    background-color: $gray-900;
  }
}

.modal-fullscreen .close {
  display: inline-block;
  text-align: right;
  align-self: flex-end;
  padding-right: 1rem;
  margin: 0;
  margin-bottom: -1rem;
  font-size: 4rem;
}

.content-text  a {
  word-break: break-all;
  color: $tacao;
}

.font-bold {
  font-weight: bold;
}

.-mx-2 {
  margin-left: -0.5rem;
  margin-right: -0.5rem;
}

@include media-breakpoint-up(lg) {
  .lg\:-mx-3 {
    margin-left: -1.0rem;
    margin-right: -1.0rem;
  }
}
