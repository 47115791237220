
.download-list {
    list-style-image: url("images/list_arrow.svg");
    line-height: 1.25;

    p {
      margin: 0;
      line-height: 1.25;
    }
}

.bg-black {
  background-color: $gray-900;
  color: #fff;
}

.bg-navy, .bg-blue {
  background-color: $navy;
  color: #fff;
}

.col--green, .col--blue, .col--black, .col--None, .col--karree49 {

}

.col--green, .col--blue, .col--black, .col--karree49 {

  .heading {
    color: $white;
  }

  .content-text {
    color:$white;
  }

  a {
    color: $link-color-dark;
    &:hover {
      color: darken($link-color-dark, 10%);
    }
  }
}

.heading {
  text-transform: uppercase;
  line-height: 100%;

  p {
    margin: 0;
  }

  strong, b {
      font-weight: 900;
  }

  &.heading--textpage {
    font-size: 2.2rem;

    @include media-breakpoint-down(md) {
      word-break: break-word;
    }

    @include media-breakpoint-up(md) {
      font-size: 3.8rem;
    }
  }
}

.heading.heading--smaller {
  font-size: 2.0rem;

  @include media-breakpoint-down(md) {
    word-break: break-word;
  }

  @include media-breakpoint-up(md) {
    font-size: 3.0rem;
  }
}

.content-text__truncated {
  .content-text__continue {
    float: right;
    margin-top: -1rem;
    margin-bottom: 1rem;
  }
}

#continuereadingModal {
  .content-text__full {
    display: block;
  }

  .content-text__truncated {
    display: none;
  }

  .close {
    display: inline-block;
    text-align: right;
    align-self: flex-end;
    padding-right: 0.5rem;
    margin: 0;
    margin-top: 0.2rem;
    margin-bottom: -0.5rem;
    font-size: 2rem;
  }

}

.content-text__full {
  display: none;
}

.content-text {
  text-align: justify;
}

.row__mainpage {
  min-height: 25vh;
}

.col__subpage {
  min-height: 25vh;
  padding-top: 3rem;
}

.col-left .content-text {
  padding-right: 1rem;

  @include media-breakpoint-up(md) {
    padding-right: 5rem;
  }
}

.col-right .content-text {
  padding-right: 1rem;

  @include media-breakpoint-up(md) {
    padding-right: 5rem;
  }
}


.stage {
    background: url("images/hintergrund_wieauto.svg");
    background-repeat: no-repeat;
    background-size: cover;

    background-position: 25%;

    //height: 25vh;

    @include media-breakpoint-up(lg) {
      background-position: center;
      //height: 33vh;
    }
}

.content-collection a {
    display: inline-block;
    border-bottom: 1px solid #fff;
    width: 100%;
    padding: 10px 1rem 12px;
}

.text-collection {
  height: 100%;
  display: flex;
  margin-right: -15px;
  margin-left: -15px;
  flex-direction: column;

  .heading {
    margin-left: -0.10rem;
    margin-top: -0.25rem;
    margin-bottom: -0.1rem;
  }

  &__links {
    flex: 1;
  }

  a {
    color: lighten($link-color, 25%);
    &:hover {
      color: lighten($link-hover-color, 50%);
    }
  }
}


.footer {
    background-color: #5db56b;
    color: #fff;
}

.img-fluid--logo {
  max-width: 100vw;
  width: auto;
  max-height: 50vh;
}

.header-intro {
  font-family: $font-family-sans-serif;
  font-size: 1.1rem;
  padding: 0.5rem;
  padding-left: 5.5rem;

  @include media-breakpoint-up(md) {
    padding-left: 7.4rem;
    font-size: 1.2rem;
  }

  @include media-breakpoint-up(lg) {
    margin-top: 0rem;
    font-size: 1.4rem;
    padding-left: 12.5rem;
  }

  @include media-breakpoint-up(xl) {
    font-size: 1.8rem;
    padding-left: 15.5rem;
  }
}
