.vermietung__tags {
  justify-content: space-evenly;
  display: flex;
  flex-wrap: wrap;

  .badge {
    margin: 0.2rem 1rem;
  }
}

.section--renting {
  font-size: 1rem;
  margin-top: 2rem;
  .heading {
    font-size: 3rem;
    margin-bottom: 2rem;
  }

  h2 {
    font-size: 2.0rem;
    margin-bottom: 2rem;
  }

  section {
    margin-bottom: 3rem;
  }

  h4 {
    font-size: 1.4rem;
    margin-bottom: 0.1rem;
  }

  .lead {
    margin-top: 2rem;
    font-size: 1.4rem;
    font-weight: 300;
    margin-bottom: 2rem;
  }

  .heading--renting {
    text-transform: none;
    line-height: .9;
    span {
      font-size: 2.0rem;
    }
    span:first-child,
    .headline--renting-strong {
      font-weight: bold;
      font-size: 2.1rem;
    }

  }

  .heading--renting-offer {
    text-transform: none;
    font-size: 2.0rem;
  }

  .table--less-spacing th, td {
    padding-top: 0.4rem;
    padding-bottom: 0.4rem;
  }

  .table--tags th {
    padding-top: 0.4rem;
    padding-bottom: 0.2rem;
  }

  .table--tags td {
    padding-top: 0.2rem;
    padding-bottom: 0.2rem;

    &:before {
      content: "✗";
      padding-right: 0.5rem;
    }
  }

}

.print-button {
  font-weight: bold;

  &.print-button--hovering {
    position: absolute;
    position: sticky;
    right: 0;
    bottom: 10vh;
    top: 90vh;
    display: inline-block;
    z-index: 1;
    left: 100vw;
  }

  @media print {
    display: none !important;
  }
}

.card-text--expose {
  line-height: 1.2;
}

.card-img-top.expose__links {
  min-height: 200px;
  position: relative;
}

.gallery-thumbs img.img-fluid {
  max-height: 100px;
}

.renting_notavailable {
  position: absolute;
  width: 14rem;
  height: auto;
  display: block;
  bottom: 0rem;
  right: 0rem;

  &.renting_notavailable--top {
    top: 0rem;
    bottom: auto;
    z-index: 100;
  }
}
