$navbar-height: $font-size-base * $line-height-base+1.25rem+1.0rem;
$navbar-offset: 1rem;

.header-container {
    //margin-bottom: - ($navbar-height * 2) - $navbar-offset;
}

.content-container {
    margin-top: $navbar-height + $navbar-offset * 0.75;
}
