@import "./header_offset.scss";

.karree49-navbar {
  font-family: $font-family-sans-serif;
  padding: 0 0.5rem;

  text-transform: uppercase;
  font-weight: 900;
  border: none;
  font-size: .9rem;

  @include media-breakpoint-up(md) {
    font-size: 0.8rem;
  }

  @include media-breakpoint-up(lg) {
    font-size: .9rem;
  }

  @include media-breakpoint-up(xl) {
    padding-right: 0;
    font-size: 1.0rem;
  }

  .navbar-brand {
    display: flex;
    margin-right: 0.5rem;
  }

  .navbar-brand .navbar-home {

    height: $navbar-brand-height - $navbar-brand-padding-y;
    width: auto;

    path,
    polyline {
      fill: rgba($tacao, 0.0);
      transition: fill 0.3s ease-in-out;
    }

    &:hover, &.is-active {
      path,
      polyline {
        fill: $tacao;
      }
    }
  }

  .navbar-nav {
    @include media-breakpoint-up(md) {
      //position: relative;
      margin-left: auto;
    }
  }

  &.navbar-expand-md .navbar-nav .nav-link {
    padding: .5rem;

    @include media-breakpoint-up(md) {
      padding: 0.75rem .35em .5rem;
    }

    @include media-breakpoint-up(lg) {
      padding: 1.0rem .69em 0.75rem;
    }

    @include media-breakpoint-up(xl) {
      padding: 1.15rem 1.2rem 0.9rem;
    }

    &:focus {
      outline: none;
      color: rgba($white, .85)
    }
  }

  .active {
    background: $tacao;
  }


  @include media-breakpoint-up(md) {
    .dropdown {
      position: relative;
    }

    .active.dropdown .dropdown-menu .dropdown-item {
      background: $tacao;
    }

    .active.dropdown .dropdown-menu.show {
      background-color: $tacao;
    }

    .dropdown .dropdown-menu {
      margin: 0;
      position: absolute;
      //width: 100%;
      left: auto;
      border: none;
      opacity: 0;
      transition: opacity 0.3s ease-in;
      justify-content: flex-end;

      &.show {
        display: block;
        opacity: 1;
        min-width: 100%;
      }
    }
    .dropdown .dropdown-menu .dropdown-item {
      //display: inline;
      //width: auto;
      background: $primary;
      transition: color 0.3s linear;
      padding: 0.25rem 1rem;
      max-width: 250px;
      flex: 1;
    }
  }

  .dropdown-item {
    font-weight: 900;
    color: $gray-300;

    &:hover,
    &.active {
      color: $white;
    }
  }

  .dropdown .dropdown-menu {
    border: none;
    font-size: 1.0rem;

    @include media-breakpoint-up(md) {
      font-size: .9rem;
    }

    @include media-breakpoint-up(xl) {
      padding-right: 0;
      font-size: 1.0rem;
    }

    &.show {
      background-color: $primary;
    }

  }
}

.submenu {
  .nav-item.parent .nav-link {
    background: $gray-500;
  }

  .nav-item.parent {
    position:relative;
    margin-right: 2rem;
  }

  .arrow-right {
    position: absolute;
    right: -1.44rem;
    top: 0;
    background: url('images/arrow.svg') no-repeat center center;
    background-size: cover;
    width: 1.44rem;
    height: 100%;
  }

}
