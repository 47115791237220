$footer-gray: #58585a;


.footer {
  background: $footer-gray;
  overflow: hidden;

  .card {
    padding: 50px;
    background-color: $footer-gray;
    color: #fff
  }

  a {
    color: #fff
  }

  a:hover {
    color: #fff
  }

  .box {
    margin-bottom: 40px;
    margin-top: 20px
  }

  .form-control {
    border: none;
    margin-left: 0;
    padding: 0;
    background-color: $footer-gray;
    color: #fff
  }

  .form-head {
    padding-left: 10px;
    padding-right: 12px;
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: 18px
  }

  .custom-control-label {
    font-size: 14px !important
  }

  .get-input {
    padding-left: 0
  }

}
